.simplifyContainer {
  border-bottom: 0;
  --brand-divider-margin: var(--space-6x) auto;
  --brand-divider-height: 6px;
  --brand-divider-width: 30px;
  --brand-divider-background-light: var(--color-indigo-400);
  --container-padding: var(--space-16x) var(--space-6x) var(--space-24x);
}

.imgSection {
  display: flex;
  justify-content: center;
}

.imgSection img {
  display: block;
}

.subHeadline {
  margin: 0 auto;
  margin-top: var(--space-8x);
  width: 200px;
}

.headline {
  margin: 0 auto;
  margin-top: var(--space-3x);
  max-width: 300px;
}

.content {
  font-size: var(--font-size-body);
}

@media (--screen-md) {
  .simplify {
    display: flex;
    gap: var(--space-14x);
    flex-direction: var(--list-property-simplify-flex-direction);
  }

  .imageSection {
    display: block;
    flex: 1 1 0%;
  }

  .simplifyContent {
    flex: 1 1 0%;
  }

  .subHeadline {
    margin-top: 0;
    font-size: var(--font-size-body);
  }
}
