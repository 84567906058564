.listPropertyPageTheme {
  --line-height: 1.5;
  --background: var(--list-property-page-theme-background);
  --color: var(--list-property-page-theme-color);
  --accent-color: var(--color-indigo-400);
  --links-color: var(--color-indigo-400);
  --links-hover: var(--color-indigo-300);
  --button-width: 160px;
  --heading-font-size: var(--font-size-2xl);
  --heading-line-height: 25px;
  --property-type-margin: 0 auto var(--space-12x) auto;
  --property-type-cta-margin: 0 0 var(--space-4x) 0;
  --value-prop-content-font-size: var(--font-size-lg);
  --property-brand-link-color: var(--color-white);
  --section-background: none;
  --divider-color: var(--color-gray-400);
  --divider-max-width: 700px;
  --divider-margin: 0 24px;

  @media (--screen-md) {
    --divider-margin: 0 auto;
  }
}

.container {
  text-align: center;
  --container-padding: var(--space-24x) var(--space-6x);
  --container-max-width: 382px;

  @media (--screen-md) {
    --container-max-width: 700px;
  }
}

.lightBlueIcon {
  background: var(--color-blue-200);
  color: var(--color-blue-600);
}

.lightLavenderIcon {
  background: var(--color-indigo-200);
  color: var(--color-indigo-600);
}

.lightPinkIcon {
  background: var(--color-violet-200);
  color: var(--color-violet-600);
}

.propertyTypeSubTitle {
  margin-top: var(--space-3x);
}
