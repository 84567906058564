.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--section-background);
  padding: var(--space-16x) var(--space-14x);
  flex-direction: var(--list-property-block-flex-direction);
  gap: var(--space-8x);

  @media (--screen-md) {
    gap: var(--space-14x);
  }
}

.container {
  --brand-divider-margin: var(--space-8x) auto;
  --brand-divider-height: 6px;
  --brand-divider-width: 30px;
  max-width: 329px;
  text-align: center;
}

.subHeadline {
  margin: 0 auto;
  width: 200px;

  @media (--screen-md) {
    font-size: var(--font-size-body);
  }
}

.headline {
  margin: var(--space-3x) auto 0;
}

.content {
  font-size: var(--font-size-body);

  p:nth-of-type(2) {
    margin-top: var(--space-5x);
  }
}

.button {
  margin-top: var(--space-8x);
}

.image {
  max-width: 100%;

  @media (--screen-md) {
    max-width: 50%;
  }
}
